// noinspection DuplicatedCode

import { Report } from '../../types';
import * as common from './controllers-common';


interface Shared {
    /** Пособие на оздоровление государственных и гражданских служащих: Сумма* */
    bfWellnessColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
    bfEcoDisasterHealthColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Подъемное пособие при служебном перемещении */
    bfMovementLiftingColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за вредные и опасные условия труда, сумма */
    bfOthGovServGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     * Единовременное пособие при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также
     * единовременное пособие при увольнении с военной службы военнослужащим срочной службы, сумма
     */
    bfFiredColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за вредные и опасные условия труда, сумма */
    cmHarmfulHazardousConditionsColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Компенсация за особые условия труда, сумма */
    cmSpecialWorkConditionsColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Сумма пособий на оздоровление */
    bfYearSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Итого */
    totalSumColGroup: Report.SubprogramDist.ColGroup | undefined;
}


const codes = {
    /** Сумма должностных окладов в месяц* */
    salaryMonthSum: 'l__salary_month_sum',

    /** Пособие на оздоровление государственных и гражданских служащих: Сумма */
    bfWellness: 'l__bf_wellness',

    /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
    bfEcoDisasterHealth: 'l__bf_eco_disaster_health',

    /** Подъемное пособие при служебном перемещении, */
    bfMovementLifting: 'l__bf_movement_lifting',

    /** Компенсация за вредные и опасные условия труда, сумма */
    bfOthGovServ: 'l__bf_oth_gov_serv',

    /**
     * Единовременное пособие при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также
     * единовременное пособие при увольнении с военной службы военнослужащим срочной службы, сумма
     */
    bfFired: 'l__bf_fired',

    /** Компенсация за вредные и опасные условия труда, сумма */
    cmHarmfulHazardousConditions: 'l__cm_harmful_hazardous_conditions',

    /** Компенсация за особые условия труда, сумма */
    cmSpecialWorkConditions: 'l__cm_special_work_conditions',

    /** Сумма пособий на оздоровление */
    bfYearSum: 'l__bf_year_sum',

    /** Итого по специфике 113 */
    total: 'l__total',
};


const defaultExport: Report.SubprogramDist.Controller = common.createControllerForSubprogramRecalculation<Shared>(
    (columns, colGroups) => ({
        bfWellnessColGroup: colGroups.get(codes.bfWellness),
        bfEcoDisasterHealthColGroup: colGroups.get(codes.bfEcoDisasterHealth),
        bfMovementLiftingColGroup: colGroups.get(codes.bfMovementLifting),
        bfOthGovServGroup: colGroups.get(codes.bfOthGovServ),
        bfFiredColGroup: colGroups.get(codes.bfFired),
        cmHarmfulHazardousConditionsColGroup: colGroups.get(codes.cmHarmfulHazardousConditions),
        cmSpecialWorkConditionsColGroup: colGroups.get(codes.cmSpecialWorkConditions),
        bfYearSumColGroup: colGroups.get(codes.bfYearSum),
        totalSumColGroup: colGroups.get(codes.total),
    }),
    (readNumber, setNumber, columns, colGroups, subprograms, fields, shared) => {
        const {
            bfWellnessColGroup,
            bfEcoDisasterHealthColGroup,
            bfMovementLiftingColGroup,
            bfOthGovServGroup,
            bfFiredColGroup,
            cmHarmfulHazardousConditionsColGroup,
            cmSpecialWorkConditionsColGroup,
            bfYearSumColGroup,
            totalSumColGroup,
        } = shared;

        /** Пособие на оздоровление государственных и гражданских служащих: Сумма* */
        const bfWellness = readNumber(bfWellnessColGroup);

        /** Пособие на оздоровление работникам, проживающим в зонах экологического бедствия */
        const bfEcoDisasterHealth = readNumber(bfEcoDisasterHealthColGroup);

        /** Подъемное пособие при служебном перемещении */
        const bfMovementLifting = readNumber(bfMovementLiftingColGroup);

        /** Компенсация за вредные и опасные условия труда, сумма */
        const bfOthGovServ  = readNumber(bfOthGovServGroup);

        /**
         * Единовременное пособие при увольнении со службы по возрасту, выходное пособие судьям при уходе в отставку и пособие депутату по истечении срока его полномочий, а также
         * единовременное пособие при увольнении с военной службы военнослужащим срочной службы, сумма
         */
        const bfFired = readNumber(bfFiredColGroup);

        /** Компенсация за вредные и опасные условия труда, сумма */
        const cmHarmfulHazardousConditions = readNumber(cmHarmfulHazardousConditionsColGroup);

        /** Компенсация за особые условия труда, сумма */
        const cmSpecialWorkConditions = readNumber(cmSpecialWorkConditionsColGroup);

        /** Сумма пособий на оздоровление */
        const totalBfYearSum: number = (
            (bfWellness || 0) + // Сумма должностных окладов в месяц * 2
            (bfEcoDisasterHealth || 0)
        );

        /** Итого по специфике 113 */
        const totalSum: number = (
            (totalBfYearSum || 0) +
            (bfMovementLifting || 0)+
            (bfOthGovServ || 0)+
            (bfFired || 0)+
            (cmHarmfulHazardousConditions || 0)+
            (cmSpecialWorkConditions || 0)
        );

        if (bfYearSumColGroup !== undefined)
            setNumber(bfYearSumColGroup, totalBfYearSum);
        if (totalSumColGroup !== undefined)
            setNumber(totalSumColGroup, totalSum);
    },
);
export default defaultExport;