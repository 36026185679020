








import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import i18n from '@/services/i18n';
import { defaultNumberInputFormats, fallbackFormatter, fallbackNumberInputFormats, fallbackParser } from '../common';
import { Utils } from '../types';


const modelChangeEvent = 'change';

const noModelValue = Symbol('modules/budget/staffing-table/NumberInput :: no value');
Object.freeze(noModelValue);


@Component
export default class NumberInput extends Vue {
    // region Model, properties
    @Model(modelChangeEvent, {
        type: Number,
        required: false,
        default: noModelValue
    })
    public readonly value!: number | null | symbol;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly formats!: Utils.NumberInput.Formats | null;

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly ignoreDefaultFormats!: boolean;

    @Prop({
        type: [Number, String],
        required: false,
        default: null
    })
    public readonly min!: string | number | null;

    private get usedMin(): number | null {
        const min = this.min;
        if (min === null) {
            return null;
        }

        if (typeof min === 'number') {
            if (min.isFinite) {
                return min;
            }
            return null;
        }

        let result: number | null;
        try {
            result = parseFloat(min);
        } catch (e) {
            console.error('modules/budget/staffing-table/NumberInput', e);
            result = null;
        }
        return result;
    }

    @Prop({
        type: [Number, String],
        required: false,
        default: null
    })
    public readonly max!: string | number | null;

    private get usedMax(): number | null {
        const max = this.max;
        if (max === null) {
            return null;
        }

        if (typeof max === 'number') {
            if (max.isFinite) {
                return max;
            }
            return null;
        }

        let result: number | null;
        try {
            result = parseFloat(max);
        } catch (e) {
            console.error('modules/budget/staffing-table/NumberInput', e);
            result = null;
        }
        return result;
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.setLocalValueFromValue();
        this.setFieldStringValueFromLocal();

        this.$watch('value', () => {
            this.setLocalValueFromValue();
        });

        this.$watch('fieldNumberValue', () => {
            if (this.localValue !== this.fieldNumberValue) {
                this.localValue = this.fieldNumberValue;
            }
        });

        this.$watch('localValue', () => {
            if (this.localValue !== null) {
                const min = this.usedMin;
                const max = this.usedMax;
                if ((min === null) || (max === null) || (min <= max)) {
                    if ((min !== null) && (this.localValue < min)) {
                        this.$nextTick(() => {
                            this.localValue = min;
                        });
                        return;
                    }
                    if ((max !== null) && (this.localValue > max)) {
                        this.$nextTick(() => {
                            this.localValue = max;
                        });
                        return;
                    }
                }
            }

            this.setFieldStringValueFromLocal();

            if (this.value !== this.localValue) {
                this.$emit(modelChangeEvent, this.localValue);
            }
        });
    }
    // endregion


    // region Used formats
    public get usedFormats(): Utils.NumberInput.Formats {
        const formats = this.formats;
        if (formats !== null) {
            return formats;
        }

        if (this.ignoreDefaultFormats) {
            return fallbackNumberInputFormats;
        }

        return defaultNumberInputFormats;
    }

    public get locale(): string {
        return i18n.locale;
    }

    public get usedFormatter(): Utils.NumberInput.Formatter {
        const locale = this.locale;

        const formats = this.usedFormats;

        if (formats.getFormatter) {
            const result = formats.getFormatter(locale);
            if (result) {
                return result;
            }
        }

        if (formats.localeToFormatterMap) {
            const result = formats.localeToFormatterMap[locale];
            if (result) {
                return result;
            }
        }

        if (formats.defaultFormatter) {
            return formats.defaultFormatter;
        }

        return fallbackFormatter;
    }

    public get usedParser(): Utils.NumberInput.Parser {
        const locale = this.locale;

        const formats = this.usedFormats;

        if (formats.getParser) {
            const result = formats.getParser(locale);
            if (result) {
                return result;
            }
        }

        if (formats.localeToParserMap) {
            const result = formats.localeToParserMap[locale];
            if (result) {
                return result;
            }
        }

        if (formats.defaultParser) {
            return formats.defaultParser;
        }

        return fallbackParser;
    }
    // endregion


    // region Local value
    private fieldStringValue = '';

    private get fieldNumberValue(): number | null {
        const formatter = this.usedFormatter;
        const parser = this.usedParser;

        return parser(formatter(parser(this.fieldStringValue)));
    }

    private localValue: number | null = null;

    private setLocalValueFromValue() {
        if ((typeof this.value !== 'symbol') && (this.localValue !== this.value)) {
            this.localValue = this.value;
        }
    }

    private setFieldStringValueFromLocal() {
        const value = (this.localValue ? this.usedFormatter(this.localValue) || '' : '');
        if (this.fieldStringValue !== value) {
            this.fieldStringValue = value;
        }
    }

    private blur() {
        this.setFieldStringValueFromLocal();
    }
    // endregion
}
