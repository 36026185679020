import VueI18n, { Values as VueI18nValues } from 'vue-i18n';
import { vue } from '@/services/i18n';


export default class I18n {
    public readonly engine: VueI18n;
    public readonly keyBase: string;

    public constructor(keyBase: string) {
        this.engine = vue;
        this.keyBase = keyBase;
    }

    public commonTranslate(key: string, values?: VueI18nValues): string {
        return String(this.engine.t(`modules.budget.staffing_table._common.${key}`, values));
    }

    public enumTranslate(enumName: string, value: string): string {
        return String(this.engine.t(`modules.budget.staffing_table._enum.${enumName}.${value}`));
    }

    public translate(key: string, values?: VueI18nValues): string {
        return String(this.engine.t(`${this.keyBase}.${key}`, values));
    }

    public get locale(): string {
        return this.engine.locale.trim().toLowerCase();
    }

    public get isKazakh(): boolean {
        return (this.locale === 'kk');
    }
}