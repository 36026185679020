














































































































































































































import { BvModalEvent } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { Comp, Dict, Report } from '../types';
import I18n from '../I18n';
import NumberInput from '../components/NumberInput.vue';


interface ISheetTableRow {
    data: Record<string, unknown>;
    subprogram15Error?: string;
    changed?: true;
    invalid?: true;
    _rowVariant?: 'success' | 'danger';
}

interface IChange {
    subprogramValueChange?: { key: string; value: number | null };
}


const i18n = new I18n('modules.budget.staffing_table.reports.*Report2Sheet*');
const orderNumberFieldKey = 'order_number';

const copySubprogramColumns = (sourceColumns: Report.Version2.Col[]): Report.Version2.Col[] => {
    const result: Report.Version2.Col[] = [];
    sourceColumns.forEach((source) => {
        const column: Report.Version2.Col = {
            id: source.id,
            sheet: source.sheet,
            orderNumber: source.orderNumber,
            titleKk: source.titleKk,
            titleRu: source.titleRu,
            subtitleKk: source.subtitleKk,
            subtitleRu: source.subtitleRu,
            field: source.field,
            dateField: source.dateField,
            specType: source.specType,
            subprogram: source.subprogram,
            hidden: source.hidden,
            excelWidth: source.excelWidth,
            useIntegerFormat: source.useIntegerFormat,
            subprogDistGroupCode: source.subprogDistGroupCode,
            subprogDistGroupType: source.subprogDistGroupType,
            resultCol: source.resultCol,
        };
        result.push(column);
    });
    return result;
};

const subprogramColumnsHasChanges = (columns1: Report.Version2.Col[], columns2: Report.Version2.Col[]): boolean => {
    if (columns1.length !== columns2.length) {
        return true;
    }

    for (let i = 0; i < columns1.length; i++) {
        const column1 = columns1[i];
        const column2 = columns2[i];

        if ((column1.subprogram !== column2.subprogram) || (column1.titleKk !== column2.titleKk) || (column1.titleRu !== column2.titleRu)) {
            return true;
        }
    }

    return false;
};

const copyData = (source: Record<string, unknown>): Record<string, unknown> => {
    const result: Record<string, unknown> = {};
    Object.getOwnPropertyNames(source).forEach((key) => {
        result[key] = source[key];
    });
    return result;
};

const dataHasChanges = (data1: Record<string, unknown>, data2: Record<string, unknown>): boolean => {
    const keys1 = Object.getOwnPropertyNames(data1);
    const keys2 = Object.getOwnPropertyNames(data2);
    if (keys1.length !== keys2.length) {
        return true;
    }

    const keys = new Set<string>([...keys1, ...keys2]);
    for (const key of keys) {
        if (data1[key] !== data2[key]) {
            return true;
        }
    }
    return false;
};

const copyDataList = (source: Array<Record<string, unknown>>): Array<Record<string, unknown>> => {
    return source.map((row) => copyData(row));
};


@Component({
    components: {
        NumberInput
    }
})
export default class Report2Sheet extends Vue {
    // region Свойства
    @Prop({
        type: Number,
        required: true
    })
    public readonly reportDate!: number;

    @Prop({
        type: Object,
        required: true
    })
    public readonly sheet!: Report.Version2.Sheet;

    @Prop({
        type: Boolean,
        required: true
    })
    public readonly divBySubprograms!: boolean;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly funcGroup!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly funcSubgroup!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly abp!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly budgetProgram!: Dict.EbkFunc | null;

    private get sheetId(): number | null {
        return this.sheet.id;
    }

    private onSheetIdChanged() {
        if (this.sheetId === null) {
            this.columns = [];
            this.rows = [];
        } else {
            this.reloadColumns();
            this.reloadRows();
            this.reloadSubprograms();
        }
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('sheetId', () => {
            this.onSheetIdChanged();
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.onSheetIdChanged();
    }
    // endregion


    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get numberFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.i18n.locale, { maximumFractionDigits: 10 });
    }

    private orderNumberFieldKey = orderNumberFieldKey;
    // endregion


    // region Подпрограммы
    private subprograms: Dict.EbkFunc[] = [];

    private loadingSubprograms = false;

    private reloadSubprograms() {
        if (this.loadingSubprograms) {
            console.error('Cannot load subprograms - another loading is running');
            return;
        }

        const funcGroupCode = this.funcGroup?.gr;
        if (typeof funcGroupCode !== 'number') {
            console.error('Cannot load budget subprograms - functional group is null');
            return;
        }

        const funcSubgroupCode = this.funcSubgroup?.pgr;
        if (typeof funcSubgroupCode !== 'number') {
            console.error('Cannot load budget subprograms - functional subgroup is null');
            return;
        }

        const abpCode = this.abp?.abp;
        if (typeof abpCode !== 'number') {
            console.error('Cannot load budget subprograms - ABP is null');
            return;
        }

        const budgetProgramCode = this.budgetProgram?.prg;
        if (typeof budgetProgramCode !== 'number') {
            console.error('Cannot load budget subprograms - budget program is null');
            return;
        }

        this.loadingSubprograms = true;
        this.subprograms = [];
        Ax<Dict.EbkFunc[]>(
            {
                url: '/api/budget/staffing_table/report/budget-subprograms'
                    + `?func-group-code=${funcGroupCode}&func-subgroup-code=${funcSubgroupCode}`
                    + `&abp-code=${abpCode}&budget-program-code=${budgetProgramCode}`
                    + `&date=${this.reportDate}`
            },
            (data) => { this.subprograms = data; },
            (error) => this.toast('danger', this.i18n.translate('error.cannot_load_subprograms', [this.sheetId]), error.toString()),
            () => { this.loadingSubprograms = false; }
        );
    }
    // endregion


    // region Колонки
    private loadingColumns = false;

    private columns: Report.Version2.Col[] = [];

    private get visibleColumns(): Report.Version2.Col[] {
        return this.columns.filter((column) => (column.hidden !== true));
    }

    private get fieldToColumnMap(): Map<string, Report.Version2.Col> {
        const result = new Map<string, Report.Version2.Col>();
        this.columns.forEach(column => {
            result.set(column.field, column);
        });
        return result;
    }

    private get maxSubprogramSumColumn(): Report.Version2.Col | null {
        const result = this.columns.find((column) => (column.specType === 'MAX_SUM_FOR_SUBPROGRAM_DIV'));
        // noinspection JSIncompatibleTypesComparison
        if (result === undefined) {
            return null;
        }
        return result;
    }

    private get subprogram15Column(): Report.Version2.Col | null {
        const result = this.columns.find((column) => (column.specType === 'SUM_FOR_SUBPROGRAM_15'));
        // noinspection JSIncompatibleTypesComparison
        if (result === undefined) {
            return null;
        }
        return result;
    }

    private reloadColumns() {
        if (this.loadingColumns) {
            console.error('Cannot load columns - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot load columns - sheet ID is null');
            return;
        }

        this.loadingColumns = true;
        this.columns = [];
        Ax<Report.Version2.Col[]>(
            { url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/columns` },
            data => {
                const orderNumberColumn: Report.Version2.Col = {
                    id: null,
                    sheet: null,
                    orderNumber: -1,
                    titleKk: '№',
                    titleRu: '№',
                    subtitleKk: '',
                    subtitleRu: '',
                    field: this.orderNumberFieldKey,
                    dateField: false,
                    specType: null,
                    subprogram: null,
                    hidden: null,
                    excelWidth: null,
                    useIntegerFormat: null,
                    subprogDistGroupCode: null,
                    subprogDistGroupType: null,
                    resultCol: false,
                };

                const columns: Report.Version2.Col[] = [orderNumberColumn];
                columns.push(...data);
                this.columns = columns;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_columns', [sheetId]), error.toString()),
            () => { this.loadingColumns = false; }
        );
    }


    private get tableFields(): Comp.TableFieldDef[] {
        const columns = this.visibleColumns;
        if (columns.length === 0) {
            return [];
        }

        const isKazakh = this.i18n.isKazakh;

        const create = (column: Report.Version2.Col): Comp.TableFieldDef => {
            let subtitle: string;
            if (isKazakh) {
                subtitle = column.subtitleKk;
            } else {
                subtitle = column.subtitleRu;
            }

            return {
                key: `data.${column.field}`,
                label: subtitle
            };
        };

        return columns.map(create);
    }

    private get tableFieldHeaders(): string[] {
        const columns = this.visibleColumns;
        if (columns.length === 0) {
            return [];
        }

        const isKazakh = this.i18n.isKazakh;

        const get = (column: Report.Version2.Col): string => {
            if (isKazakh) {
                return column.titleKk;
            }
            return column.titleRu;
        };

        return columns.map(get);
    }

    private get dateFieldKeys(): string[] {
        const columns = this.columns;
        if (columns.length === 0) {
            return [];
        }

        const result: string[] = [];
        columns.forEach(column => {
            if (column.dateField) {
                result.push(column.field);
            }
        });
        return result;
    }


    // noinspection JSMethodCanBeStatic
    private getFieldForKey(key: string): string {
        let field: string;
        if (key.startsWith('data.')) {
            field = key.substring(5);
        } else {
            field = key;
        }
        return field;
    }

    private getSubheaderText(key: string): string {
        const field = this.getFieldForKey(key);

        const column = this.fieldToColumnMap.get(field);
        // noinspection JSIncompatibleTypesComparison
        if (column !== undefined) {
            if (this.i18n.isKazakh) {
                return column.subtitleKk;
            }
            return column.subtitleRu;
        }

        return '';
    }
    // endregion


    // region Колонки подпрограмм
    private subprogramColumnsModalVisible = false;

    private get subprogramColumns(): Report.Version2.Col[] {
        return this.columns.filter((column) => ((column.specType === 'SUM_FOR_SUBPROGRAM') || (column.specType === 'SUM_FOR_SUBPROGRAM_15')));
    }

    private get subprogramColumnFirstOrderNumber(): number {
        const subprogramColumns = this.subprogramColumns;
        if (subprogramColumns.isNotEmpty) {
            return subprogramColumns[0].orderNumber;
        }

        const columns = this.columns;
        if (columns.isNotEmpty) {
            return columns[0].orderNumber;
        }

        return 0;
    }

    private get budgetSubprogramUsedCodes(): number[] {
        return this.changedSubprogramColumns
            .map((column) => (column.subprogram))
            .filter((code) => (code !== null)) as number[];
    }

    private get availableBudgetSubprograms(): Dict.EbkFunc[] {
        return this.subprograms.filter((subprogram) => (this.budgetSubprogramUsedCodes.notIncludes(subprogram.ppr ?? 0)));
    }

    private changedSubprogramColumns: Report.Version2.Col[] = [];

    private get subprogramColumnsHasChanges(): boolean {
        return subprogramColumnsHasChanges(this.subprogramColumns, this.changedSubprogramColumns);
    }


    private showSubprogramColumnModal() {
        this.changedSubprogramColumns = copySubprogramColumns(this.subprogramColumns);
        this.subprogramColumnsModalVisible = true;
    }

    private addSubprogramColumn(subprogram: Dict.EbkFunc) {
        const subprogramCode = subprogram.ppr;
        if (subprogramCode === null) {
            return;
        }

        const subprogram15Column = this.subprogram15Column;

        let excelWidth: number | null;
        let useIntegerFormat: boolean | null;
        let subprogDistGroupCode: string | null;
        let subprogDistGroupType: Report.Version2.ColSubprogDistGroupType | null;
        if (subprogram15Column === null) {
            excelWidth = null;
            useIntegerFormat = null;
            subprogDistGroupCode = null;
            subprogDistGroupType = null;
        } else {
            excelWidth = subprogram15Column.excelWidth;
            useIntegerFormat = subprogram15Column.useIntegerFormat
            subprogDistGroupCode = subprogram15Column.subprogDistGroupCode;
            subprogDistGroupType = subprogram15Column.subprogDistGroupType;
        }

        const column: Report.Version2.Col = {
            id: null,
            sheet: null,
            orderNumber: (this.subprogramColumnFirstOrderNumber + (this.changedSubprogramColumns.length - 1)),
            titleKk: `Сумма для подпрограммы "${subprogramCode}"`,
            titleRu: `Сумма для подпрограммы "${subprogramCode}"`,
            subtitleKk: 'тыс. тг.',
            subtitleRu: 'тыс. тг.',
            field: `l__sum-for-subprogram--${subprogramCode}`,
            dateField: false,
            specType: 'SUM_FOR_SUBPROGRAM',
            subprogram: subprogramCode,
            hidden: null,
            excelWidth,
            useIntegerFormat,
            subprogDistGroupCode,
            subprogDistGroupType,
            resultCol: false,
        };

        this.changedSubprogramColumns.push(column);
    }

    private removeSubprogramColumn(column: Report.Version2.Col) {
        const index = this.changedSubprogramColumns.indexOf(column);
        if (index < 0) {
            return;
        }

        const changedSubprogramColumns = [...this.changedSubprogramColumns];
        changedSubprogramColumns.splice(index, 1);
        this.changedSubprogramColumns = changedSubprogramColumns;
    }

    private updateChangedSubprogramColumnOrders() {
        const firstIndex = this.subprogramColumnFirstOrderNumber;
        this.changedSubprogramColumns.forEach((column, index) => {
            column.orderNumber = (firstIndex + index);
        });
        this.changedSubprogramColumns = [...this.changedSubprogramColumns];
    }

    private moveSubprogramColumnUp(column: Report.Version2.Col) {
        const index = this.changedSubprogramColumns.indexOf(column);
        if (index < 1) {
            return;
        }

        this.changedSubprogramColumns.splice(index, 1);
        this.changedSubprogramColumns.splice(index - 1, 0, column);
        this.updateChangedSubprogramColumnOrders();
    }

    private moveSubprogramColumnDown(column: Report.Version2.Col) {
        const index = this.changedSubprogramColumns.indexOf(column);
        if ((index < 0) || (index > (this.changedSubprogramColumns.length - 1))) {
            return;
        }

        this.changedSubprogramColumns.splice(index, 1);
        this.changedSubprogramColumns.splice(index + 1, 0, column);
        this.updateChangedSubprogramColumnOrders();
    }

    private onSubprogramColumnsModalHide(ev: BvModalEvent) {
        if (ev.trigger === 'ok') {
            this.saveChangedSubprogramColumns();
        }
    }

    private saveChangedSubprogramColumns() {
        if (!this.subprogramColumnsHasChanges) {
            console.error('Cannot save changed subprogram columns - there is no changes');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot save changed subprogram columns - sheet ID is null');
            return;
        }

        if (this.loadingColumns) {
            console.error('Cannot save changed subprogram columns - another loading is running');
            return;
        }

        this.loadingColumns = true;
        Ax(
            {
                method: 'POST',
                url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/subprogram-columns`,
                data: this.changedSubprogramColumns
            },
            () => {
                setTimeout(() => {
                    this.onSheetIdChanged();
                });
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_save_subprogram_columns', [sheetId]), error.toString()),
            () => { this.loadingColumns = false; }
        );
    }
    // endregion


    // region Строки
    private loadingRows = false;

    private rows: Array<Record<string, unknown>> = [];

    private originalRows: Array<Record<string, unknown>> = [];

    private get tableRows(): ISheetTableRow[] {
        const columns = this.columns;
        if (columns.length === 0) {
            return [];
        }

        const subprogram15Column = this.subprogram15Column;
        const subprogram15ColumnField = (subprogram15Column === null ? '' : subprogram15Column.field);

        return this.rows.map((row, index) => {
            let subprogram15Error: string | undefined;
            let changed: true | undefined;
            let invalid: true | undefined;
            let _rowVariant: 'success' | 'danger' | undefined;

            const subprogram15Value = row[subprogram15ColumnField];
            if ((typeof subprogram15Value === 'number') && (subprogram15Value < 0)) {
                subprogram15Error = 'modules.budget.staffing_table.reports.*Report2Sheet*.error.negative_value';
                invalid = true;
            }

            if ((this.originalRows.length > index) && dataHasChanges(row, this.originalRows[index])) {
                changed = true;
            }

            if (invalid === true) {
                _rowVariant = 'danger';
            } else if (changed === true) {
                _rowVariant = 'success';
            }

            return {
                data: row,
                subprogram15Error,
                changed,
                invalid,
                _rowVariant
            };
        });
    }

    private get hasChanges(): boolean {
        for (const tableRow of this.tableRows) {
            if (tableRow.changed === true) {
                return true;
            }
        }
        return false;
    }

    private get hasInvalid(): boolean {
        for (const tableRow of this.tableRows) {
            if (tableRow.invalid === true) {
                return true;
            }
        }
        return false;
    }

    private get totalRow(): Record<string, unknown> {
        const result: Record<string, unknown> = {};
        this.rows.forEach(row => {
            Object.getOwnPropertyNames(row).forEach(key => {
                const rowValue = row[key];
                if ((typeof rowValue === 'number') && (this.dateFieldKeys.indexOf(key) < 0)) {
                    let totalValue = result[key];

                    if (typeof totalValue === 'number') {
                        totalValue = totalValue + rowValue;
                    } else {
                        totalValue = rowValue;
                    }

                    result[key] = totalValue;
                }
            });
        });
        return result;
    }

    private reloadRows() {
        if (this.loadingRows) {
            console.error('Cannot load rows - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot load rows - sheet ID is null');
            return;
        }

        this.loadingRows = true;
        Ax<Array<Record<string, unknown>>>(
            { url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/rows` },
            data => {
                this.rows = data;
                this.originalRows = copyDataList(data);
            },
            error => {
                this.toast('danger', this.i18n.translate('error.cannot_load_rows', [sheetId]), error.toString());
                this.rows = [];
                this.originalRows = [];
            },
            () => { this.loadingRows = false; }
        );
    }
    // endregion


    // region Ячейки данных
    private getColumnValue(row: Record<string, unknown>, key: string): unknown {
        const field = this.getFieldForKey(key);

        const value = row[field];

        if (typeof value === 'number') {
            if (field === this.orderNumberFieldKey) {
                return value;
            }
            if (this.dateFieldKeys.indexOf(key) < 0) {
                return this.numberFormat.format(value);
            }
            return this.dateFormat.format(new Date(value));
        }
        return value;
    }

    private isSubprogram15Column(key: string): boolean {
        const subprogram15Column = this.subprogram15Column;
        if (subprogram15Column === null) {
            return false;
        }

        const field = this.getFieldForKey(key);
        return (field === subprogram15Column.field);
    }

    private isEditableSubprogramSumColumn(key: string): boolean {
        const field = this.getFieldForKey(key);

        const column = this.fieldToColumnMap.get(field);
        // noinspection JSIncompatibleTypesComparison
        if (column === undefined) {
            return false;
        }
        return (column.specType === 'SUM_FOR_SUBPROGRAM');
    }

    private getEditableSubprogramSumValue(row: Record<string, unknown>, key: string): number | null {
        const field = this.getFieldForKey(key);
        const value = row[field];
        if (typeof value === 'number') {
            return value;
        }
        return null;
    }

    private applyChangeTimeout: number | null = null;

    private applyChange(row: ISheetTableRow, change?: IChange) {
        if (this.applyChangeTimeout !== null) {
            clearTimeout(this.applyChangeTimeout);
        }
        this.applyChangeTimeout = setTimeout(() => {
            this.applyChangeTimeout = null;
            const data = row.data;

            // region Применение изменений
            if (change !== undefined) {
                if (change.subprogramValueChange !== undefined) {
                    const subprogram15Column = this.subprogram15Column;
                    const field = this.getFieldForKey(change.subprogramValueChange.key);

                    const maxSubprogramSum = ((): (number | null) => {
                        const maxSubprogramSumColumn = this.maxSubprogramSumColumn;
                        if (maxSubprogramSumColumn === null) {
                            return null;
                        }

                        const result = data[maxSubprogramSumColumn.field];
                        if (typeof result === 'number') {
                            return result;
                        }
                        return null;
                    })();

                    if (change.subprogramValueChange.value === null) {
                        delete data[field];
                    } else {
                        data[field] = change.subprogramValueChange.value;
                    }

                    if ((maxSubprogramSum !== null) && (subprogram15Column !== null)) {
                        let subprogramsSum = 0;
                        this.columns.forEach((column) => {
                            if (column.specType === 'SUM_FOR_SUBPROGRAM') {
                                const value = data[column.field];
                                if (typeof value === 'number') {
                                    subprogramsSum += value;
                                }
                            }
                        });

                        data[subprogram15Column.field] = (maxSubprogramSum - subprogramsSum);
                    }
                }
            }
            // endregion
        }, 500);
    }

    private saveSubprogramValues() {
        if (this.loadingRows) {
            console.error('Cannot save rows - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot save rows - sheet ID is null');
            return;
        }

        const subprogramFields = this.columns
            .filter((column) => ((column.specType === 'SUM_FOR_SUBPROGRAM') || (column.specType === 'SUM_FOR_SUBPROGRAM_15')))
            .map((column) => (column.field));

        const savedDataList: Array<Record<string, unknown>> = [];
        this.tableRows
            .filter((tableRow) => (tableRow.changed === true))
            .map((tableRow) => (tableRow.data))
            .forEach((row, rowIndex) => {
                const id = row.id;
                if (typeof id !== 'number') {
                    return;
                }

                const savedData: Record<string, null | number | string | boolean> & { id: number } = { id };
                subprogramFields.forEach((field) => {
                    const value = row[field];
                    const valueType = (typeof value);
                    switch (typeof value) {
                        case 'undefined':
                            savedData[field] = null;
                            break;
                        case 'string':
                        case 'number':
                        case 'boolean':
                            savedData[field] = value;
                            break;
                        default:
                            if (value === null) {
                                savedData[field] = value;
                            } else if ((value as unknown) instanceof Date) {
                                savedData[field] = (value as Date).getTime();
                            } else {
                                console.warn(`Row #${id} (${rowIndex + 1}-th row), value "${field}" - unexpected type "${valueType}"`);
                            }
                            break;
                    }
                });

                savedDataList.push(savedData);
            });

        if (savedDataList.isEmpty) {
            console.error('Cannot save rows - no changes');
            return;
        }

        this.loadingRows = true;
        Ax(
            {
                method: 'POST',
                url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/subprogram-values`,
                data: savedDataList
            },
            () => {
                this.toast('success', '', this.i18n.commonTranslate('saved'));
                setTimeout(() => { this.onSheetIdChanged(); });
            },
            (error) => this.toast('danger', this.i18n.translate('error.cannot_save_subprogram_columns', [sheetId]), error.toString()),
            () => { this.loadingRows = false; }
        );
    }
    // endregion
}
