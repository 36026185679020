












































































































































































































































import { BvModalEvent } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Ax } from "@/utils";
import I18n from '../../I18n';
import { Dict, Report } from '../../types';
import controllers from './constrollers';
import * as common from './controllers-common';
import Report2Row from './Report2Row.vue';


interface RowComponent {
    getChangedRow(): Record<string, unknown>;
    resetRow(): void;
    canTakeValueFromNullSubprogram(column: Report.Version2.Col): boolean;
    takeValueFromNullSubprogram(column: Report.Version2.Col): void;
}


const i18n = new I18n('modules.budget.staffing_table.reports.*Report2SubprogramDistSheet*');
const orderNumberFieldKey = 'order_number';

const moveSubprogram = (subprogram: Dict.EbkFunc, source: Array<Dict.EbkFunc>, target: Array<Dict.EbkFunc>) => {
    const index = source.indexOf(subprogram);
    if (index >= 0) source.splice(index, 1);

    target.push(subprogram);
    target.sort((subprogram1, subprogram2) => {
        const code1 = (subprogram1.ppr || 0);
        const code2 = (subprogram2.ppr || 0);
        return (code1 - code2);
    });
};

const copyTotalRow = (columns: Array<Report.Version2.Col>, source: Record<string, number | null>): Record<string, number | null> => {
    const result: Record<string, number | null> = {};

    columns.forEach((column, index) => {
        if (index === 0) return;

        const sourceValue = source[column.field];
        result[column.field] = (typeof sourceValue === 'number' ? sourceValue : null);
    });

    return result;
};


@Component({
    components: {
        'row': Report2Row,
    },
})
export default class Report2SubprogramDistSheet extends Vue {
    // region Properties
    @Prop({
        type: Object,
        required: true,
    })
    public readonly report!: Report;

    @Prop({
        type: Object,
        required: true,
    })
    public readonly sheet!: Report.Version2.Sheet;

    public get sheetId(): number | null {
        return this.sheet.id;
    }

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly divBySubprograms!: boolean;

    @Prop({
        type: Boolean,
        default: false,
        required: false,
    })
    public readonly debug!: boolean;

    @Prop({
        type: Number,
        required: true
    })
    public readonly reportDate!: number;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly funcGroup!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly funcSubgroup!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly abp!: Dict.EbkFunc | null;

    @Prop({
        type: Object,
        required: false,
        default: null
    })
    public readonly budgetProgram!: Dict.EbkFunc | null;

    public get subprogramReloadTrigger(): [number, Dict.EbkFunc | null, Dict.EbkFunc | null, Dict.EbkFunc | null, Dict.EbkFunc | null] {
        return [
            this.reportDate,
            this.funcGroup,
            this.funcSubgroup,
            this.abp,
            this.budgetProgram,
        ];
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols, JSMethodCanBeStatic
    private created() {
        this.$watch('sheetId', () => {
            this.reloadSubprogramsIfPossible();
            this.reloadColumnsIfPossible();
            this.reloadRowsIfPossible();
        });
        this.$watch('subprogramReloadTrigger', () => { this.reloadSubprogramsIfPossible(); });
        this.$watch('rows', () => {
            setTimeout(
                () => {
                    this.updateRowComponents();
                },
                1000,
            );
        });
    }

    // noinspection JSUnusedLocalSymbols, JSMethodCanBeStatic
    private mounted() {
        this.reloadSubprogramsIfPossible();
        this.reloadColumnsIfPossible();
        this.reloadRowsIfPossible();
    }
    // endregion


    // region Утилиты
    private i18n = i18n;
    private orderNumberFieldKey = orderNumberFieldKey;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get numberFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.i18n.locale, { maximumFractionDigits: 10 });
    }

    private get subprogramDistController(): Report.SubprogramDist.Controller | null {
        const report = this.report;
        const result = controllers.get(report.form);
        return (result || null);
    }
    // endregion


    // region Подпрограммы
    private loadingSubprograms = false;
    private subprograms: Array<Dict.EbkFunc> = [];
    private subprogramsModalVisible = false;

    private get usedSubprograms(): Array<Dict.EbkFunc> {
        const result: Array<Dict.EbkFunc> = [];
        this.subprograms.forEach(subprogram => {
            const code = subprogram.ppr;
            if ((code !== null) && this.usedSubprogramCodes.includes(code)) {
                result.push(subprogram);
            }
        });
        return result;
    }

    private get availableSubprograms(): Array<Dict.EbkFunc> {
        const result: Array<Dict.EbkFunc> = [];
        this.subprograms.forEach(subprogram => {
            const code = subprogram.ppr;
            if ((code !== null) && (!this.usedSubprogramCodes.includes(code))) {
                result.push(subprogram);
            }
        });
        return result;
    }

    private modalUsedSubprograms: Array<Dict.EbkFunc> = [];
    private modalAvailableSubprograms: Array<Dict.EbkFunc> = [];

    private get modalUsedSubprogramCodes(): Array<number> {
        const result: Array<number> = [];
        this.modalUsedSubprograms.forEach(subprogram => {
            const subprogramCode = subprogram.ppr;
            if (subprogramCode !== null) result.push(subprogramCode);
        });
        return result;
    }

    private get modalSubprogramsHasChanges(): boolean {
        if (
            (this.usedSubprograms.length !== this.modalUsedSubprograms.length)
            ||
            (this.availableSubprograms.length !== this.modalAvailableSubprograms.length)
        ) return true;

        for (let i = 0; i < this.usedSubprograms.length; i++) {
            if (this.usedSubprograms[i] !== this.modalUsedSubprograms[i]) {
                return true;
            }
        }

        for (let i = 0; i < this.availableSubprograms.length; i++) {
            if (this.availableSubprograms[i] !== this.modalAvailableSubprograms[i]) {
                return true;
            }
        }

        return false;
    }


    private reloadSubprogramsIfPossible() {
        if (
            (!this.loadingSubprograms)
            && (typeof this.funcGroup?.gr === 'number')
            && (typeof this.funcSubgroup?.pgr === 'number')
            && (typeof this.abp?.abp === 'number')
            && (typeof this.budgetProgram?.prg === 'number')
        ) {
            this.reloadSubprograms();
        }
    }

    private reloadSubprograms() {
        if (this.loadingSubprograms) {
            console.error('Cannot load subprograms - another loading is running');
            return;
        }

        const funcGroupCode = this.funcGroup?.gr;
        if (typeof funcGroupCode !== 'number') {
            console.error('Cannot load budget subprograms - functional group is null');
            return;
        }

        const funcSubgroupCode = this.funcSubgroup?.pgr;
        if (typeof funcSubgroupCode !== 'number') {
            console.error('Cannot load budget subprograms - functional subgroup is null');
            return;
        }

        const abpCode = this.abp?.abp;
        if (typeof abpCode !== 'number') {
            console.error('Cannot load budget subprograms - ABP is null');
            return;
        }

        const budgetProgramCode = this.budgetProgram?.prg;
        if (typeof budgetProgramCode !== 'number') {
            console.error('Cannot load budget subprograms - budget program is null');
            return;
        }

        this.loadingSubprograms = true;
        this.subprograms = [];
        Ax<Dict.EbkFunc[]>(
            {
                url: '/api/budget/staffing_table/report/budget-subprograms'
                    + `?func-group-code=${funcGroupCode}&func-subgroup-code=${funcSubgroupCode}`
                    + `&abp-code=${abpCode}&budget-program-code=${budgetProgramCode}`
                    + `&date=${this.reportDate}`
            },
            (data) => {
                const subprograms = [...data];
                subprograms.sort((subprogram1, subprogram2) => {
                    const code1 = (subprogram1.ppr === null ? 0 : subprogram1.ppr);
                    const code2 = (subprogram2.ppr === null ? 0 : subprogram2.ppr);
                    return (code1 - code2);
                });
                this.subprograms = subprograms;
            },
            (error) => this.toast('danger', this.i18n.translate('error.cannot_load_subprograms', [this.sheetId]), error.toString()),
            () => { this.loadingSubprograms = false; }
        );
    }


    private getSubprogramText(subprogram: Dict.EbkFunc): string {
        const code = subprogram.ppr || subprogram.id;

        let title: string;
        if (this.i18n.isKazakh) {
            title = subprogram.nameKk || subprogram.shortNameKk || String(code);
        } else {
            title = subprogram.nameRu || subprogram.nameRu || String(code);
        }
        return `${code} - ${title}`;
    }


    private showSubprogramsModal() {
        this.modalUsedSubprograms = [...this.usedSubprograms];
        this.modalAvailableSubprograms = [...this.availableSubprograms];
        this.subprogramsModalVisible = true;
    }

    private onAddSubprogram(subprogram: Dict.EbkFunc) {
        const modalAvailableSubprograms = [...this.modalAvailableSubprograms];
        const modalUsedSubprograms = [...this.modalUsedSubprograms];

        moveSubprogram(subprogram, modalAvailableSubprograms, modalUsedSubprograms);

        this.modalAvailableSubprograms = modalAvailableSubprograms;
        this.modalUsedSubprograms = modalUsedSubprograms;
    }

    private onRemoveSubprogram(subprogram: Dict.EbkFunc) {
        const modalUsedSubprograms = [...this.modalUsedSubprograms];
        const modalAvailableSubprograms = [...this.modalAvailableSubprograms];

        moveSubprogram(subprogram, modalUsedSubprograms, modalAvailableSubprograms);

        this.modalUsedSubprograms = modalUsedSubprograms;
        this.modalAvailableSubprograms = modalAvailableSubprograms;
    }

    private onSubprogramsModalHide(ev: BvModalEvent) {
        if (ev.trigger === 'ok') this.saveSubprograms();
    }

    private saveSubprograms() {
        if (this.loadingSubprograms) {
            console.error('Cannot apply new subprograms - another loading is running');
            return;
        }

        const controller = this.subprogramDistController;
        if (controller === null) {
            console.error('Cannot apply new subprograms - form controller is null');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot apply new subprograms - sheet ID is null');
            return;
        }

        const { columns, rows } = controller.subprogramsChanged(this.usedSubprogramCodes, this.modalUsedSubprogramCodes, { columns: this.columns, rows: this.rows });
        if (columns.length > 0) columns.splice(0, 1);

        this.loadingSubprograms = true;
        Ax(
            {
                url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/subprogram-dist/content`,
                method: 'POST',
                data: { columns, rows },
            },
            () => {
                this.toast(
                    'success',
                    this.i18n.commonTranslate('saved'),
                    this.i18n.translate('subprograms_saved'),
                );
                setTimeout(() => { this.reloadColumnsIfPossible(); });
                setTimeout(() => { this.reloadRowsIfPossible(); });
            },
            (error) => this.toast('danger', this.i18n.translate('error.cannot_save_subprograms', [this.sheetId]), error.toString()),
            () => { this.loadingSubprograms = false; }
        );
    }
    // endregion


    // region Колонки
    private loadingColumns = false;
    private columns: Array<Report.Version2.Col> = [];

    private get visibleColumns(): Array<Report.Version2.Col> {
        if (this.debug) {
            return this.columns;
        } else {
            const result: Array<Report.Version2.Col> = [];
            this.columns.forEach(column => {
                if (column.hidden !== true) {
                    result.push(column);
                }
            });
            return result
        }
    }

    private get usedSubprogramCodes(): Array<number> {
        const result: Array<number> = [];
        this.columns.forEach(column => {
            if (column.specType === 'SUBPROG_DIST') {
                const subprogram = column.subprogram;
                if ((subprogram !== null) && (!result.includes(subprogram))) {
                    result.push(subprogram);
                }
            }
        });
        result.sort((code1, code2) => (code1 - code2));
        return result;
    }

    private get columnFields(): Set<string> {
        return common.getColumnFields(this.columns);
    }


    private reloadColumnsIfPossible() {
        if ((!this.loadingColumns) && (this.sheetId !== null)) this.reloadColumns();
    }

    private reloadColumns() {
        if (this.loadingColumns) {
            console.error('Cannot reload columns - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot reload columns - sheet ID is null');
            return;
        }


        this.loadingColumns = true;
        this.columns = [];
        Ax<Report.Version2.Col[]>(
            { url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/columns` },
            data => {
                const orderNumberColumn: Report.Version2.Col = {
                    id: null,
                    sheet: null,
                    orderNumber: -1,
                    titleKk: '№',
                    titleRu: '№',
                    subtitleKk: '',
                    subtitleRu: '',
                    field: this.orderNumberFieldKey,
                    dateField: false,
                    specType: null,
                    subprogram: null,
                    hidden: null,
                    excelWidth: null,
                    useIntegerFormat: null,
                    subprogDistGroupCode: null,
                    subprogDistGroupType: null,
                    resultCol: false,
                };

                const columns: Report.Version2.Col[] = [orderNumberColumn];
                columns.push(...data);
                columns.sort((column1, column2) => (column1.orderNumber - column2.orderNumber));

                this.columns = columns;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_columns', [sheetId]), error.toString()),
            () => { this.loadingColumns = false; }
        );
    }


    // noinspection JSMethodCanBeStatic
    private getColumnWidth(column: Report.Version2.Col): number {
        const stored = column.excelWidth;
        if ((stored === null) || stored < 50) {
            return 100;
        } else {
            return stored;
        }
    }
    // endregion


    // region Строки
    private loadingRows = false;
    private rows: Array<Record<string, unknown>> = [];
    private changedRowIndices: Set<number> = new Set();
    private errorRowIndices: Set<number> = new Set();
    private rowComponents: Array<Vue & RowComponent> = [];

    private get hasChangedRows(): boolean {
        return (this.changedRowIndices.size > 0);
    }

    private get hasRowErrors(): boolean {
        return (this.errorRowIndices.size > 0);
    }


    private resetRows(rows: Array<Record<string, unknown>>, resetRowComponents?: true) {
        this.changedRowIndices = new Set();
        this.errorRowIndices = new Set();
        this.rows = [...rows];

        const totalRow: Record<string, number | null> = {};
        this.columns.forEach((column, index) => {
            if (index === 0) return;
            totalRow[column.field] = null;
        });
        rows.forEach((row) => {
            this.columns.forEach((column) => {
                const field = column.field;
                const rowValue = row[field];
                if (typeof rowValue === 'number') {
                    const savedValue = totalRow[field];
                    if (typeof savedValue === 'number') {
                        totalRow[field] = savedValue + rowValue;
                    } else {
                        totalRow[field] = rowValue;
                    }
                } else {
                    // noinspection JSIncompatibleTypesComparison
                    if (totalRow[field] === undefined) {
                        totalRow[field] = null;
                    }
                }
            });
        });
        this.totalRow = totalRow;

        setTimeout(() => {
            if (resetRowComponents) {
                this.rowComponents.forEach(rowComponent => {
                    rowComponent.resetRow();
                });
            }
        });
    }

    private reloadRowsIfPossible() {
        if ((!this.loadingRows) && (this.sheetId !== null)) this.reloadRows();
    }

    private reloadRows() {
        if (this.loadingRows) {
            console.error('Cannot load rows - another loading is running');
            return;
        }

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot load rows - sheet ID is null');
            return;
        }

        this.loadingRows = true;
        this.rows = [];
        this.totalRow = {};
        Ax<Array<Record<string, unknown>>>(
            { url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/rows` },
            data => { this.resetRows(data); },
            error => {
                this.toast('danger', this.i18n.translate('error.cannot_load_rows', [sheetId]), error.toString());
                this.rows = [];
            },
            () => { this.loadingRows = false; }
        );
    }

    private onRowChanged(index: number, changed: boolean) {
        if (changed) {
            if (this.changedRowIndices.has(index)) return;

            const set = new Set(this.changedRowIndices);
            set.add(index);
            this.changedRowIndices = set;
        } else {
            if (!this.changedRowIndices.has(index)) return;

            const set = new Set(this.changedRowIndices);
            set.delete(index);
            this.changedRowIndices = set;
        }
    }

    private onRowErrorChanged(index: number, invalid: boolean) {
        if (invalid) {
            if (this.errorRowIndices.has(index)) return;

            const set = new Set(this.errorRowIndices);
            set.add(index);
            this.errorRowIndices = set;
        } else {
            if (!this.errorRowIndices.has(index)) return;

            const set = new Set(this.errorRowIndices);
            set.delete(index);
            this.errorRowIndices = set;
        }
    }

    private saveRows() {
        if (this.loadingRows) {
            console.error('Cannot save rows - another loading is running');
            return;
        }

        if (this.hasRowErrors) {
            console.error('Cannot save rows - some rows are invalid');
            return;
        }

        if (!this.hasChangedRows) {
            console.error('Cannot save rows - no changed rows');
            return;
        }

        const rowComponents = this.rowComponents;
        if (rowComponents.length === 0) {
            console.error('Cannot save rows - cannot find changed row components');
            return;
        }

        const changedRows: Array<Record<string, unknown>> = rowComponents.map(rowComponent => rowComponent.getChangedRow());

        const sheetId = this.sheetId;
        if (sheetId === null) {
            console.error('Cannot save rows - sheet ID is null');
            return;
        }

        this.loadingRows = true;
        Ax(
            {
                url: `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/subprogram-dist/rows`,
                method: 'POST',
                data: changedRows,
            },
            () => {
                this.toast(
                    'success',
                    this.i18n.commonTranslate('saved'),
                    this.i18n.translate('rows_saved'),
                );
                setTimeout(() => { this.reloadColumnsIfPossible(); });
                setTimeout(() => { this.reloadRowsIfPossible(); });
            },
            (error) => this.toast('danger', this.i18n.translate('error.cannot_save_rows', [this.sheetId]), error.toString()),
            () => { this.loadingRows = false; }
        );
    }

    private canTakeValueFromNullSubprogram(column: Report.Version2.Col): boolean {
        for (const rowComponent of this.rowComponents) {
            if (rowComponent.canTakeValueFromNullSubprogram(column)) {
                return true;
            }
        }
        return false;
    }

    private takeValueFromNullSubprogram(ev: Event, column: Report.Version2.Col): void {
        const button = ((): HTMLButtonElement | undefined => {
            if (ev.target instanceof Node) {
                let el: Node | null | undefined = ev.target;
                // noinspection JSIncompatibleTypesComparison
                while ((el !== undefined) && (el !== null)) {
                    if (el instanceof HTMLButtonElement) break;
                    el = el.parentElement;
                }
                if (el instanceof HTMLButtonElement) return el;
            }
            return undefined;
        })();
        if (button !== undefined) {
            setTimeout(() => {
                button.blur();
            });
        }

        this.rowComponents.forEach(rowComponent => rowComponent.takeValueFromNullSubprogram(column));
    }


    private updateRowComponents() {
        const rowComponents: Array<Vue & RowComponent> = [];

        const rowRefs = this.$refs.rows;
        if (Array.isArray(rowRefs)) {
            rowRefs.forEach((rowRef) => {
                const typedComponent = (rowRef as unknown as (Vue & RowComponent));
                rowComponents.push(typedComponent);
            });
        } else if (rowRefs instanceof Vue) {
            const typedComponent = (rowRefs as unknown as (Vue & RowComponent));
            rowComponents.push(typedComponent);
        }

        this.rowComponents = rowComponents;
    }
    // endregion


    // region Итоговая строка
    private totalRow: Record<string, number | null> = {};

    private onValueChanged(changedRowIndex: number, oldRow: Record<string, unknown>, newRow: Record<string, unknown>) {
        const changedFields: Array<string> = [];
        this.columns.forEach((column) => {
            const field = column.field;
            if (oldRow[field] !== newRow[field]) {
                changedFields.push(field);
            }
        });

        if (changedFields.length === 0) return;

        // /* debug */ const changedValues: Record<string, number | null | undefined> = {};
        const newTotal = copyTotalRow(this.columns, this.totalRow);
        changedFields.forEach((field) => {
            let totalValue : number | null = null;

            this.rows.forEach((row, index) => {
                let rowValue: unknown;
                if (index == changedRowIndex) {
                    rowValue = newRow[field];
                } else {
                    rowValue = row[field];
                }

                if (typeof rowValue === 'number') {
                    if (totalValue === null) {
                        totalValue = rowValue;
                    } else {
                        totalValue += rowValue;
                    }
                }
            });

            // /* debug */ changedValues[field] = totalValue;
            newTotal[field] = totalValue;
        });
        // /* debug */ console.log('onValueChanged:', changedFields, changedValues);
        this.totalRow = newTotal;
    }

    private getValueOfTotal(column: Report.Version2.Col): unknown {
        const value = this.totalRow[column.field];

        if (typeof value === 'number') {
            return this.numberFormat.format(value);
        }

        return value;
    }
    // endregion
}
