import type { Report } from '../../types';
import * as common from './controllers-common';


interface Shared {
    /** Соц. налог: Сумма налога в год */
    snSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Соц. отчисления в гос. фонд соц. страхования: Сумма социальных отчислений в год */
    soSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Обяз. соц. мед. страхование: Сумма отчислений на обязательное социальное медицинское страхование в год */
    osmsSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Всего */
    totalColGroup: Report.SubprogramDist.ColGroup | undefined;
}


const codes = {
    /** Соц. налог: Сумма налога в год */
    snSum: 'l__sn__sum',

    /** Соц. отчисления в гос. фонд соц. страхования: Сумма социальных отчислений в год */
    soSum: 'l__so_sum',

    /** Обяз. соц. мед. страхование: Сумма отчислений на обязательное социальное медицинское страхование в год */
    osmsSum: 'l__osms_sum',

    /** Всего */
    total: 'l__total',
};


const defaultExport: Report.SubprogramDist.Controller = common.createControllerForSubprogramRecalculation<Shared>(
    (columns, colGroups) => ({
        snSumColGroup: colGroups.get(codes.snSum),
        soSumColGroup: colGroups.get(codes.soSum),
        osmsSumColGroup: colGroups.get(codes.osmsSum),
        totalColGroup: colGroups.get(codes.total),
    }),
    (readNumber, setNumber, columns, colGroups, subprograms, fields, shared) => {
        const {
            snSumColGroup,
            soSumColGroup,
            osmsSumColGroup,
            totalColGroup,
        } = shared;


        /** Соц. налог: Сумма налога в год */
        const snSum = readNumber(snSumColGroup);

        /** Соц. отчисления в гос. фонд соц. страхования: Сумма социальных отчислений в год */
        const soSum = readNumber(soSumColGroup);

        /** Обяз. соц. мед. страхование: Сумма отчислений на обязательное социальное медицинское страхование в год */
        const osmsSum = readNumber(osmsSumColGroup);

        let total: (number | null);
        if ((snSum === null) && (soSum === null) && (osmsSum === null)) {
            total = null;
        } else {
            total = (
                (snSum || 0)
                + (soSum || 0)
                + (osmsSum || 0)
            );
        }

        if (totalColGroup !== undefined)
            setNumber(totalColGroup, total);
    },
);
export default defaultExport;