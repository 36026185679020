// noinspection DuplicatedCode

import { Report } from '../../types';
import * as common from './controllers-common';


// noinspection SpellCheckingInspection
interface Shared {
    /** Сумма должностных окладов в месяц* */
    salaryMonthSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Сумма должностного оклада в месяц с учетом повышения* */
    salaryMonthSumWithRuralIncreaseSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Сумма доплат в месяц */
    apTotalColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Сумма надбавок в месяц */
    alTotalColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц: Сумма */
    apFmaamcfmdapupmColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Оклад по воинским званиям */
    apMilitaryRankSalaryColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     * Итого основной заработной платы в месяц*
     *
     * ```
     * Сумма должностных окладов в месяц*
     * + Сумма доплат в месяц
     * + Сумма надбавок в месяц
     * + Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц
     * + Оклад по воинским званиям
     * + Компенсация за особые условия труда, сумма
     * ```
     */
    totalSalaryMonthSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     * Итого основной заработной платы в месяц с учетом повышения*
     *
     * ```
     * Сумма должностного оклада в месяц с учетом повышения*
     * + Сумма доплат в месяц
     * + Сумма надбавок в месяц
     * + Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц
     * + Оклад по воинским званиям
     * + Компенсация за особые условия труда, сумма
     * ```
     */
    totalSalaryMonthSumWithRuralIncreaseSumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     * Итого основной заработной платы в год*
     *
     * `Итого основной заработной платы в месяц* * 12`
     */
    totalSalaryYearSumColGroup: Report.SubprogramDist.ColGroup | undefined;
}


// noinspection SpellCheckingInspection
const codes = {
    /** Сумма должностных окладов в месяц* */
    salaryMonthSum: 'l__salary_month_sum_without_rural_increase',

    /** Сумма должностного оклада в месяц с учетом повышения* */
    salaryMonthSumWithRuralIncreaseSum: 'l__salary_month_sum_with_rural_increase',

    /** Сумма доплат в месяц */
    apTotal: common.codes.addPaymentTotal,

    /** Сумма надбавок в месяц */
    alTotal: common.codes.allowanceTotal,

    /** Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц: Сумма */
    apFmaamcfmdapupm: 'c__ead__misc__ap_fmaamcfmdapupm',

    /** Оклад по воинским званиям */
    apMilitaryRankSalary: 'c__ead__misc__ap_military_rank_salary',

    /** Итого основной заработной платы в месяц* */
    totalSalaryMonthSum: 'l__total_salary_month_sum',

    /** Итого основной заработной платы в месяц с учетом повышения* */
    totalSalaryMonthSumWithRuralIncreaseSum: 'l__total_salary_month_sum_with_rural_increase',

    /** Итого основной заработной платы в год* */
    totalSalaryYearSum: 'l__total_salary_year_sum',
};


// noinspection SpellCheckingInspection
const defaultExport: Report.SubprogramDist.Controller = common.createControllerForSubprogramRecalculation<Shared>(
    (columns, colGroups) => ({
        salaryMonthSumColGroup: colGroups.get(codes.salaryMonthSum),
        salaryMonthSumWithRuralIncreaseSumColGroup: colGroups.get(codes.salaryMonthSumWithRuralIncreaseSum),
        apTotalColGroup: colGroups.get(codes.apTotal),
        alTotalColGroup: colGroups.get(codes.alTotal),
        apFmaamcfmdapupmColGroup: colGroups.get(codes.apFmaamcfmdapupm),
        apMilitaryRankSalaryColGroup: colGroups.get(codes.apMilitaryRankSalary),
        totalSalaryMonthSumColGroup: colGroups.get(codes.totalSalaryMonthSum),
        totalSalaryMonthSumWithRuralIncreaseSumColGroup: colGroups.get(codes.totalSalaryMonthSumWithRuralIncreaseSum),
        totalSalaryYearSumColGroup: colGroups.get(codes.totalSalaryYearSum),
    }),
    (readNumber, setNumber, columns, colGroups, subprograms, fields, shared) => {
        // noinspection SpellCheckingInspection
        const {
            salaryMonthSumColGroup,
            salaryMonthSumWithRuralIncreaseSumColGroup,
            apTotalColGroup,
            alTotalColGroup,
            apFmaamcfmdapupmColGroup,
            apMilitaryRankSalaryColGroup,
            totalSalaryMonthSumWithRuralIncreaseSumColGroup,
            totalSalaryMonthSumColGroup,
            totalSalaryYearSumColGroup,
        } = shared;


        /** Сумма должностных окладов в месяц* */
        const salaryMonthSum = readNumber(salaryMonthSumColGroup);

        /** Сумма должностного оклада в месяц с учетом повышения* */
        const salaryMonthSumWithRuralIncreaseSum = readNumber(salaryMonthSumWithRuralIncreaseSumColGroup);

        /** Сумма доплат в месяц */
        const apTotal = readNumber(apTotalColGroup);

        /** Сумма надбавок в месяц */
        const alTotal = readNumber(alTotalColGroup);

        // noinspection SpellCheckingInspection
        /** Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц: Сумма */
        const apFmaamcfmdapupm = readNumber(apFmaamcfmdapupmColGroup);

        /** Оклад по воинским званиям */
        const apMilitaryRankSalary = readNumber(apMilitaryRankSalaryColGroup);

        /** Итого основной заработной платы в год */
        const totalSum = readNumber(totalSalaryYearSumColGroup);

        /** Итого основной заработной платы в месяц или Итого основной заработной платы в месяц с учетом повышения* */
        const totalSalaryMonthSum: number = (
            (salaryMonthSumWithRuralIncreaseSum || salaryMonthSum || 0) //     Сумма должностных окладов в месяц*
            + (apTotal || 0) //     + Сумма доплат в месяц
            + (alTotal || 0) //     + Сумма надбавок в месяц
            + (apFmaamcfmdapupm || 0) //     + Зафиксированное денежное довольствие и денежная компенсация на содержание жилища и оплату коммунальных услуг в месяц
            + (apMilitaryRankSalary || 0) //     + Оклад по воинским званиям
        );

        /** Итого основной заработной платы в год* */
        const totalSalaryYearSum: number = (
            (totalSum || 0) // Сумма должностных окладов в год*
        );

        if (totalSalaryMonthSumWithRuralIncreaseSumColGroup !== undefined)
            setNumber(totalSalaryMonthSumWithRuralIncreaseSumColGroup, totalSalaryMonthSum);
        if (totalSalaryMonthSumColGroup !== undefined)
            setNumber(totalSalaryMonthSumColGroup, totalSalaryMonthSum);
        if (totalSalaryYearSumColGroup !== undefined)
            setNumber(totalSalaryYearSumColGroup, totalSalaryYearSum);
    },
);
export default defaultExport;