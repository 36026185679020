// noinspection DuplicatedCode

import { Report } from '../../types';
import * as common from './controllers-common';


interface Shared {
    /** Сумма двух должностных окладов в год для премирования* */
    premiumColGroup: Report.SubprogramDist.ColGroup | undefined;

    /**
     * Единовременные денежные выплаты и надбавки к должностным окладам работникам органов государственных доходов и работникам структурного подразделения
     * Министерств по инвестициям и развитию и юстиции РК
     */
    oneTimePaymentColGroup: Report.SubprogramDist.ColGroup | undefined;

    /** Итого */
    totalSumColGroup: Report.SubprogramDist.ColGroup | undefined;
}


const codes = {
    /** Сумма должностных окладов в месяц* */
    salaryMonthSum: 'l__salary_month_sum',

    /** Увеличение заработной платы: Сумма */
    salaryMonthIncreaseColumn: 'l__salary_month_increase',

    /** Сумма двух должностных окладов в год для премирования* */
    premium: 'l__premium',

    /**
     * Единовременные денежные выплаты и надбавки к должностным окладам работникам органов государственных доходов и работникам структурного подразделения
     * Министерств по инвестициям и развитию и юстиции РК
     */
    oneTimePaymentColumn: 'l__one_time_payment',

    /** Итого */
    total: 'l__total',
};


const defaultExport: Report.SubprogramDist.Controller = common.createControllerForSubprogramRecalculation<Shared>(
    (columns, colGroups) => ({
        premiumColGroup: colGroups.get(codes.premium),
        oneTimePaymentColGroup: colGroups.get(codes.oneTimePaymentColumn),
        totalSumColGroup: colGroups.get(codes.total),
    }),
    (readNumber, setNumber, columns, colGroups, subprograms, fields, shared,) => {
        const {
            premiumColGroup,
            oneTimePaymentColGroup,
            totalSumColGroup,
        } = shared;

        /** Сумма должностных окладов в месяц* */
        const premium = readNumber(premiumColGroup);

        /**
         * Единовременные денежные выплаты и надбавки к должностным окладам работникам органов государственных доходов и работникам структурного подразделения
         * Министерств по инвестициям и развитию и юстиции РК
         */
        const oneTimePayment = readNumber(oneTimePaymentColGroup);

        /** Итого основной заработной платы в месяц или Итого основной заработной платы в месяц с учетом повышения* */
        const totalSumPremium: number = (
            (premium || 0) + // Сумма должностных окладов в месяц * 2
            (oneTimePayment || 0) // Единовременные денежные выплаты и надбавки
        );

        if (totalSumColGroup !== undefined)
            setNumber(totalSumColGroup, totalSumPremium);
    },
);
export default defaultExport;