import { Report } from '../../types';


import form_01_0111 from './form-01-111';
import form_01_0116 from './form-01-116';
import form_01_0121 from './form-01-121';
import form_01_0122 from './form-01-122';
import form_01_0124 from './form-01-124';
import form_01_0135 from './form-01-135';
import form_02_0111 from './form-02-111';
import form_02_0114 from './form-02-114';
import form_03_0135 from './form-03-135';
import form_04_0111 from './form-04-111';
import form_05_0111 from './form-05-111';
import form_06_0111 from './form-06-111';
import form_07_0111 from './form-07-111';
import form_08_0111 from './form-08-111';
import form_09_0111 from './form-09-111';
import form_10_0111 from './form-10-111';
import form_11_0111 from './form-11-111';
import form_13_0111 from './form-13-111';
import form_01_0131 from './form-01-131';
import form_02_0131 from './form-02-131';
import form_01_0132 from './form-01-132';
import form_01_0112 from './form-01-112';
import form_01_0113 from './form-01-113';



const defaultExport = new Map<Report.FormKey, Report.SubprogramDist.Controller>();
defaultExport.set('F_01_111', form_01_0111);
defaultExport.set('F_01_116', form_01_0116);
defaultExport.set('F_01_121', form_01_0121);
defaultExport.set('F_01_122', form_01_0122);
defaultExport.set('F_01_124', form_01_0124);
defaultExport.set('F_01_135', form_01_0135);
defaultExport.set('F_02_111', form_02_0111);
defaultExport.set('F_02_114', form_02_0114);
defaultExport.set('F_03_135', form_03_0135);
defaultExport.set('F_04_111', form_04_0111);
defaultExport.set('F_05_111', form_05_0111);
defaultExport.set('F_06_111', form_06_0111);
defaultExport.set('F_07_111', form_07_0111);
defaultExport.set('F_08_111', form_08_0111);
defaultExport.set('F_09_111', form_09_0111);
defaultExport.set('F_10_111', form_10_0111);
defaultExport.set('F_11_111', form_11_0111);
defaultExport.set('F_13_111', form_13_0111);
defaultExport.set('F_01_131', form_01_0131);
defaultExport.set('F_02_131', form_02_0131);
defaultExport.set('F_01_132', form_01_0132);
defaultExport.set('F_01_112', form_01_0112);
defaultExport.set('F_01_113', form_01_0113);

export default defaultExport;
export {
    /* XX_111 */ form_01_0111, form_02_0111, form_04_0111, form_05_0111, form_06_0111, form_07_0111, form_08_0111, form_09_0111, form_10_0111, form_11_0111, form_13_0111,
    /* XX_112 */ form_01_0112,
    /* XX_113 */ form_01_0113,
    /* XX_116 */ form_01_0116,
    /* XX_114 */ form_02_0114,
    /* XX_121 */ form_01_0121,
    /* XX_122 */ form_01_0122,
    /* XX_124 */ form_01_0124,
    /* XX_131 */ form_01_0131, form_02_0131,
    /* XX_132 */ form_01_0132,
    /* XX_135 */ form_01_0135, form_03_0135,
};