













































import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '../../I18n';
import NumberInput from '../../components/NumberInput.vue';
import { Report } from '../../types';
import * as common from './controllers-common';


const orderNumberFieldKey = 'order_number';
const rowChangedEvent = 'row-changed';
const valueChangedEvent = 'value-changed';
const rowErrorChangedEvent = 'row-error-changed';

const i18n = new I18n('modules.budget.staffing_table.reports.*Report2SubprogramDistRow*');

const rowHasChanges = (columns: Array<Report.Version2.Col>, original: Record<string, unknown>, changed: Record<string, unknown>, preparedFields?: Set<string>): boolean => {
    const fields = (preparedFields || common.getColumnFields(columns));

    for (const field of fields) {
        const originalValue = (original[field] || null);
        const changedValue = (changed[field] || null);
        if (originalValue !== changedValue) {
            return true;
        }
    }

    return false;
};


@Component({
    components: {
        NumberInput,
    },
})
export default class Report2Row extends Vue {
    // region Properties
    @Prop({
        type: Object,
        required: false,
        default: null,
    })
    public readonly controller!: Report.SubprogramDist.Controller | null;

    @Prop({
        type: Array,
        required: true,
    })
    public readonly columns!: Array<Report.Version2.Col>;

    @Prop({
        type: Set,
        required: true,
    })
    public readonly columnFields!: Set<string>;

    @Prop({
        type: Array,
        required: true,
    })
    public readonly visibleColumns!: Array<Report.Version2.Col>;

    @Prop({
        type: Number,
        required: true,
    })
    public readonly orderNumber!: number;

    @Prop({
        type: Object,
        required: true,
    })
    public readonly row!: Record<string, unknown>;

    @Prop({
        type: Intl.DateTimeFormat,
        required: true,
    })
    public readonly dateFormat!: Intl.DateTimeFormat;

    @Prop({
        type: Intl.NumberFormat,
        required: true,
    })
    public readonly numberFormat!: Intl.NumberFormat;

    @Prop({
        type: Boolean,
        required: true,
    })
    public readonly subprogramsFound!: boolean;
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('row', () => { this.resetRow(); });
        this.$watch('changed', (newValue: boolean) => { this.$emit(rowChangedEvent, newValue); });
        this.$watch('hasError', (newValue: boolean) => { this.$emit(rowErrorChangedEvent, newValue); });
        this.$watch('changeApplyTimeoutHandler', (newHandler: number | null, oldHandler: number | null) => {
            if (oldHandler !== null) clearTimeout(oldHandler);
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.resetRow();
    }
    // endregion


    // region Утилиты
    private orderNumberFieldKey = orderNumberFieldKey;

    private i18n = i18n;
    // endregion


    // region Строка
    private changedRow: Record<string, unknown> = {};

    private get changed(): boolean {
        return rowHasChanges(this.columns, this.row, this.changedRow, this.columnFields);
    }

    private get errorFields(): Set<string> {
        const subprogramsFound = this.subprogramsFound;
        const result = new Set<string>();

        const row = this.changedRow;
        this.columns.forEach(column => {
            if ((column.hidden !== true) && (column.specType === 'SUBPROG_DIST') && (column.subprogDistGroupType !== null) && (column.subprogDistGroupType !== 'MAX')) {
                const field = column.field;
                const value = row[field];

                if (typeof value === 'number') {
                    if (!Number.isFinite(value)) {
                        result.add(field);
                    } else if (column.subprogram === null) {
                        if (subprogramsFound && (value !== 0)) {
                            result.add(field);
                        }
                    } else {
                        if (value < 0) {
                            result.add(field);
                        }
                    }
                } else if ((value !== undefined) && (value !== null)) {
                    result.add(field);
                }
            }
        });

        return result;
    }

    private get hasError(): boolean {
        return (this.errorFields.size > 0);
    }

    private get rowVariant(): 'success' | 'warning' | null {
        if (this.hasError) return 'warning';
        if (this.changed) return 'success';
        return null;
    }

    private resetRow() {
        if (this.changed) {
            this.changedRow = common.copyRow(this.row);
        }
    }

    // Используется в ./Report2SubprogramDistSheet.vue
    // noinspection JSUnusedGlobalSymbols
    public getChangedRow(): Record<string, unknown> {
        return common.copyRow(this.changedRow);
    }
    // endregion


    // region Значения
    private changeApplyTimeoutHandler: number | null = null;

    private isEditable(column: Report.Version2.Col): boolean {
        return (
            (this.controller !== null)
            && (column.subprogDistGroupType === 'SUBPROGRAM_EDITABLE')
            && (typeof column.subprogram === 'number')
        );
    }

    private getValue(column: Report.Version2.Col): unknown {
        if (column.field === this.orderNumberFieldKey) return this.orderNumber;

        const value = this.changedRow[column.field];

        if (typeof value === 'number') {
            if (column.dateField) {
                return this.dateFormat.format(new Date(value));
            } else {
                return this.numberFormat.format(value);
            }
        }

        return value;
    }

    private getNumberValue(column: Report.Version2.Col): number | null {
        const value = this.changedRow[column.field];
        if (typeof value === 'number') return value;
        else return null;
    }

    private onNumberValueChanged(column: Report.Version2.Col, value: number | null) {
        const controller = this.controller;
        if (controller === null) {
            console.error('Cannot apply change - form controller is null');
            return;
        }

        const change: Record<string, unknown> = {};
        change[column.field] = value;

        const oldChangedRow = common.copyRow(this.changedRow);
        this.changedRow = controller.applyChange(this.columns, this.changedRow, change);

        this.$emit(valueChangedEvent, column, oldChangedRow, this.changedRow);
    }

    private cellVariant(column: Report.Version2.Col): 'danger' | null {
        if (this.errorFields.has(column.field)) return 'danger';
        else return null;
    }

    private getNullSubprogramColumnFor(column: Report.Version2.Col): Report.Version2.Col | undefined {
        if (column.hidden === true) return undefined;
        if (column.specType !== 'SUBPROG_DIST') return undefined;
        if (column.subprogDistGroupType !== 'SUBPROGRAM_EDITABLE') return undefined;
        if (column.subprogDistGroupCode === null) return undefined;
        if (column.subprogram === null) return undefined;

        return this.columns.find((testColumn) => {
            if (testColumn.hidden === true) return false;
            if (testColumn.specType !== 'SUBPROG_DIST') return false;
            if (testColumn.subprogDistGroupType !== 'SUBPROGRAM_READONLY') return false;
            if (testColumn.subprogram !== null) return false;
            // noinspection RedundantIfStatementJS
            if (testColumn.subprogDistGroupCode !== column.subprogDistGroupCode) return false;
            return true;
        });
    }

    public canTakeValueFromNullSubprogram(column: Report.Version2.Col): boolean {
        const nullSubprogramColumn = this.getNullSubprogramColumnFor(column);
        if (nullSubprogramColumn === undefined) return false;

        const value = this.getNumberValue(nullSubprogramColumn);
        return (
            (value !== null)
            && Number.isFinite(value)
            && (value > 0)
        );
    }

    public takeValueFromNullSubprogram(column: Report.Version2.Col) {
        const nullSubprogramColumn = this.getNullSubprogramColumnFor(column);
        if (nullSubprogramColumn === undefined) return;

        const nullSubprogramValue = this.getNumberValue(nullSubprogramColumn);
        if ((nullSubprogramValue === null) || (!Number.isFinite(nullSubprogramValue)) || (nullSubprogramValue <= 0)) return;

        const currentValue = (this.getNumberValue(column) || 0);
        const newValue = currentValue + nullSubprogramValue;
        setTimeout(() => {
            this.onNumberValueChanged(column, newValue);
        });
    }

    private onTakeValueFromNullSubprogramClick(ev: Event, column: Report.Version2.Col) {
        const button = ((): HTMLButtonElement | undefined => {
            if (ev.target instanceof Node) {
                let el: Node | null | undefined = ev.target;
                // noinspection JSIncompatibleTypesComparison
                while ((el !== undefined) && (el !== null)) {
                    if (el instanceof HTMLButtonElement) break;
                    el = el.parentElement;
                }
                if (el instanceof HTMLButtonElement) return el;
            }
            return undefined;
        })();
        if (button !== undefined) {
            setTimeout(() => {
                button.blur();
            });
        }

        this.takeValueFromNullSubprogram(column);
    }
    // endregion
}
